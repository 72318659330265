import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import teacherService from '../../service/teacherService';

export type Teacher = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  created_at: string;
  isActive: boolean;
  checkoutSession: boolean;
  exp_date: string;
};
type TeacherState = {
    teacher: any;
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    errorMessage: string;
  }

const initialState: TeacherState = {
  teacher: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

export const getAllTeachersAction = createAsyncThunk('teachers', async (id: string, thunkAPI) => {
  try {
    return await teacherService.teachers(thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

const handleRejected = (state: TeacherState, action: any) => {
  state.isLoading = false;
  state.isSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};
const handleFulfilled = (state: TeacherState, action: any) => {
  if(action?.payload?.status !== 500 || action?.payload?.status !== 511){
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
    state.teacher = action?.payload?.teacher ?  action.payload  : null;
  }else{
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = action.payload.data.errorMessage
    state.teacher = null
  }
};
export const teacherSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    resetTeacher: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeachersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTeachersAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(getAllTeachersAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});



export const { resetTeacher } = teacherSlice.actions;
export default teacherSlice.reducer;