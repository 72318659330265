import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Text,
  List,
  ListItem,
  ListIcon,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { FiClock, FiArrowRight, FiArrowLeft, FiInfo, FiMic } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

export const SpeakingTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();

  return (
    <Box minH="100vh" py={10} bg="gray.50">
      <Container maxW="container.md">
        <Card 
          bg="white" 
          shadow="sm"
          borderRadius="xl"
          overflow="hidden"
        >
          <CardHeader 
            bg="green.50" 
            borderBottom="1px" 
            borderColor="green.100"
          >
            <HStack justify="space-between" align="center">
              <Heading size="md" color="green.700">
                Speaking Test Instructions
              </Heading>
              <Icon as={FiMic} color="green.500" boxSize={5} />
            </HStack>
          </CardHeader>

          <CardBody py={6}>
            <VStack align="stretch" spacing={6}>
              <Box>
                <HStack mb={4} color="green.600">
                  <Icon as={FiClock} />
                  <Text fontWeight="medium">Time Limit: 20 minutes</Text>
                </HStack>

                <List spacing={4} color="gray.700">
                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="green.500" mt={1} />
                    <Text>
                      In this practice test, no score will be provided for any of the Speaking tasks. 
                      You can refer to the Performance Standards for Speaking or listen to sample 
                      speaking responses at the end of the test.
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="green.500" mt={1} />
                    <Text>
                      For this practice test, use a timer to ensure you complete each task 
                      within the given time limit.
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="green.500" mt={1} />
                    <Text>
                      The practice test will not record your answers. To save your responses, 
                      use your computer microphone or your own recording device (cellphone, 
                      digital recorder, etc.).
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="green.500" mt={1} />
                    <Text>
                      On the official test, if you don't finish a task in time, it will automatically 
                      move to the next task. You cannot go back. In this practice test, you must 
                      click "NEXT" to proceed.
                    </Text>
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </CardBody>

          <CardFooter 
            borderTop="1px" 
            borderColor="gray.100"
            bg="gray.50"
            justify="space-between"
          >
            <Button
              leftIcon={<FiArrowLeft />}
              variant="ghost"
              onClick={() => navigate(-1)}
              _hover={{ transform: "translateX(-4px)" }}
              transition="all 0.2s"
            >
              Back
            </Button>
            <Button
              rightIcon={<FiArrowRight />}
              colorScheme="green"
              as="a"
              href={`/speaking-test/${testNum}`}
              _hover={{ transform: "translateX(4px)" }}
              transition="all 0.2s"
            >
              Start Test
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </Box>
  );
};
