import {
  Box,
  Button,
  Heading,
  Text,
  Stack,
  Icon,
  Center,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { formatNumber } from "../../utils/helperFuntions";
import { FaCheck, FaTimes } from "react-icons/fa";
import React from "react";
import { PlanSelection } from "./PlanSelection";
import { useLocation } from "react-router-dom";

type PackageCardProps = {
  title: string;
  value: number;
  pricePerStudent: number;
  price: number;
  numberOfMockTest: number | string;
  selected: boolean;
  onClick: () => void;
  bgColor: string;
  isPopular?: boolean;
};

const PackageCard: React.FC<PackageCardProps> = ({
  title,
  value,
  pricePerStudent,
  price,
  numberOfMockTest,
  selected,
  onClick,
  bgColor,
  isPopular,
}) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      borderWidth={selected ? "2px" : ""}
      borderColor={selected ? "#319795" : ""}
      borderRadius="md"
      mt={4}
      ml={9}
      // p={7}
      textAlign="left"
      boxShadow="2xl"
      bgColor={bgColor}
      position="relative"
      _before={{
        content: isPopular ? '"POPULAR"' : '""',
        position: "absolute",
        top: "-10px",
        left: "50%",
        transform: "translateX(-50%)",
        bgColor: isPopular ? "#d53f8c" : "",
        color: "white",
        padding: "2px 5px",
        borderRadius: "md",
        fontSize: "sm",
      }}
    >
      <Box p={7}>
      <Heading fontSize="xl" mb={4} textAlign={"center"}>
        {title}
      </Heading>
      <Box py={4} px={12} mb={9}>
        <HStack justifyContent="center">
          <Text fontSize="3xl" fontWeight="600">
            $
          </Text>
          <Text fontSize="5xl" fontWeight="900">
            {formatNumber(price)}
          </Text>
          <Text fontSize="3xl" color="gray.500">
            /day
          </Text>
        </HStack>
      </Box>
      </Box>
      <Box bg={"#FFF"} p={7}>
      <Text mb={2}>
        <Icon as={FaCheck} color={"#8cc1c1"} /> {numberOfMockTest} Free Mock Test
      </Text>
      <Text mb={2}>
        <Icon as={FaCheck} color={"#8cc1c1"} />{" "}
        {title === "Free" ? 10 : "Unlimited"} AI-Powered Scoring
      </Text>
      <Text mb={2}>
        <Icon as={FaCheck} color={"#8cc1c1"} />{" "}
        {title === "Free" || title === "Student Essential"
          ? "Student Dashboard"
          : "Manage Students"}{" "}
      </Text>
      <Text mb={2}>
        {title === "Free" || title === "Student Essential" ? (
          <Icon as={FaTimes} color={"gray.500"} />
        ) : (
          <Icon as={FaCheck} color={"#8cc1c1"} />
        )}{" "}
        Teacher Dashboard
      </Text>
      <Text mb={2}>
        {title === "Free" || title === "Student Essential" ? (
          <Icon as={FaTimes} color={"gray.500"} />
        ) : (
          <Icon as={FaCheck} color={"#8cc1c1"} />
        )}{" "}
        Administrative Controls
      </Text>

      <Center>
        {title === "Student Essential" ? (
          <PlanSelection />
        ) : (
          <Button
            mt={4}
            width={"full"}
            as={"a"}
            href="/sign-up"
            colorScheme="teal"
            variant="outline"
            _hover={{ bg: "#319795", color: "#FFF", borderColor: "#319795"}}
          >
            Sign Up
          </Button>
        )}{" "}
      </Center>
      </Box>
    </Box>
  );
};

export const PackagePlans: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<number>(200);
  const pricingRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToPricing && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  
  useEffect(() => {
    if (pricingRef.current) {
      window.pricingRef = pricingRef;
    }
  }, []);

  const plans = [
    {
      title: "Free",
      value: 60,
      pricePerStudent: 15,
      price: 0,
      numberOfMockTest: 1,
      bgColor: "pink.50",
    },
    {
      title: "Student Essential",
      value: 60,
      pricePerStudent: 12,
      price: 1,
      numberOfMockTest: "All",
      bgColor: "blue.50",
      isPopular: true,
    },
    // {
    //   title: "Teacher Essential",
    //   value: 60,
    //   pricePerStudent: 8,
    //   price: 2,
    //   numberOfMockTest: "All",
    //   bgColor: "green.50",
    // },
  ];

  return (
    <Stack spacing={8} p={4} w={["full", "70%"]} mx="auto" ref={pricingRef}>
      <Box p={[6, 8]} rounded="md" bg="white">
        <Heading as="h1" size="xl" textAlign="center" mb={20}>
          Select Package
        </Heading>
       <Center w={"100%"}>
        <Box  textAlign={"center"} w={"full"}>
          {/* <SimpleGrid columns={[1, null, 3]} spacing={5} w={"full"} > */}
          {plans.map((plan) => (
            <PackageCard
              key={plan.price}
              title={plan.title}
              value={plan.value}
              pricePerStudent={plan.pricePerStudent}
              price={plan.price}
              numberOfMockTest={plan.numberOfMockTest}
              selected={selectedPlan === plan.price}
              onClick={() => setSelectedPlan(plan.price)}
              bgColor={plan.bgColor}
              isPopular={plan.isPopular}
            />
          ))}
        {/* </SimpleGrid> */}
        </Box>
       
        </Center>
      </Box>
    </Stack>
  );
};
