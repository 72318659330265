import {
  Box,
  Container,
  Heading,
  Text,
  Avatar,
  VStack,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaQuoteLeft } from 'react-icons/fa';

type TestimonialProps = {
  content: string;
  name: string;
  image: string;
}

const Testimonial = ({ content, name, image }: TestimonialProps) => {
  return (
    <VStack
      spacing={8}
      bg="white"
      p={8}
      rounded="xl"
      shadow="sm"
      borderWidth="1px"
      borderColor="gray.100"
      position="relative"
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-4px)",
        shadow: "md",
        borderColor: "teal.200",
      }}
    >
      <Icon
        as={FaQuoteLeft}
        w={8}
        h={8}
        color="teal.400"
        opacity={0.3}
        position="absolute"
        top={4}
        left={4}
      />
      <Text
        color="gray.600"
        fontSize="md"
        lineHeight="tall"
        textAlign="center"
        pt={6}
      >
        {content}
      </Text>
      <VStack>
        <Avatar
          size="lg"
          src={""}
          name={name}
          border="4px solid"
          borderColor="teal.50"
        />
        <Text fontWeight="bold" fontSize="md" color="gray.900">
          {name}
        </Text>
        <Text
          fontSize="sm"
          color="teal.500"
          fontWeight="medium"
        >
          CELPIP Test Taker
        </Text>
      </VStack>
    </VStack>
  );
};

export const Testimonials = () => {
  const testimonials = [
    {
      content: "I had the pleasure of being one of your students during my preparation for the CELPIP test. Thanks to your expert guidance and comprehensive materials, I was able to achieve the results I needed.",
      name: "Ashe Dravendra",
      image: "",
    },
    {
      content: "The system helped me improve my score by 3 points! The practice tests are incredibly realistic. I loved practicing in my free time and receiving instant feedback.",
      name: "Chewi Jkonyi",
      image: "",
    },
    {
      content: "The AI-powered scoring in the writing and speaking sections are exceptional. I highly recommend this program to anyone preparing for the CELPIP test.",
      name: "Ligaya Tala",
      image: "",
    },
  ];

  return (
    <Box bg={"#fbf2fd"} py={20}>
      <Container maxW="container.xl">
        <VStack spacing={16}>
          <VStack spacing={4} textAlign="center">
            <Heading
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              bgGradient="linear(to-r, teal.600, blue.500)"
              bgClip="text"
            >
              What Our Students Say
            </Heading>
            <Text 
              color="gray.600" 
              fontSize="lg"
              maxW="2xl"
            >
              See how our platform has helped students achieve their CELPIP goals
            </Text>
          </VStack>

          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={8}
            width="full"
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial
                key={index}
                content={testimonial.content}
                name={testimonial.name}
                image={testimonial.image}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};
