import React, { FC } from "react";
import { ReadingAnswerTable } from "../test/reading/answer/ReadingAnswerTable";
import { TestResult } from "../../types/test-result-type";
import { ReadingAndListeningResponseType } from "../../types/reading-types";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  HStack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useListeningAndReadingScoring } from "../../hooks/use-listening-and-reading-scoring";
import { FiBook, FiChevronRight } from "react-icons/fi";

type ReadingResultPreviewProps = {
  result: TestResult;
};

export const ReadingResultPreview: FC<ReadingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { responses, totalCorrectAnswers, totalScore } = useListeningAndReadingScoring({
    feedback: result.feedback,
  });

  return (
    <Box>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="blue"
        size="lg"
        width="full"
        _hover={{
          transform: "translateY(-2px)",
          shadow: "md",
        }}
        transition="all 0.2s"
      >
        <HStack width="full" justify="space-between">
          <HStack>
            <Icon as={FiBook} />
            <Text>View Reading Result</Text>
          </HStack>
          <Icon as={FiChevronRight} />
        </HStack>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderRadius="xl" overflow="hidden">
          <ModalHeader 
            borderBottom="1px" 
            borderColor="gray.100"
            bg="blue.50"
            py={4}
          >
            <VStack align="start" spacing={1}>
              <HStack>
                <Icon as={FiBook} color="blue.500" />
                <Text color="blue.700">Reading Test Results</Text>
              </HStack>
              <Text color="blue.600" fontSize="sm" pl={6}>
                Total Score: {totalScore} ({totalCorrectAnswers} correct answers)
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py={6}>
            <ReadingAnswerTable
              totalCorrectAnswers={totalCorrectAnswers}
              totalScore={totalScore}
              responses={responses as ReadingAndListeningResponseType[]}
            />
          </ModalBody>

          <ModalFooter 
            borderTop="1px" 
            borderColor="gray.100"
            bg="gray.50"
          >
            <Button
              variant="ghost"
              onClick={onClose}
              size="lg"
              _hover={{
                bg: "gray.100",
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
