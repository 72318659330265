import React, { FC } from "react";
import { TestResult } from "../../types/test-result-type";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Feedback } from "../../types/speaking-type";
import { SpeakingResultAnswer } from "../test/speaking/SpeakingResultAnswer";
import { FiMic, FiChevronRight } from "react-icons/fi";

type SpeakingResultPreviewProps = {
  result: TestResult;
};

export const SpeakingResultPreview: FC<SpeakingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const feedbackData = JSON.parse(result.feedback) as Feedback;

  return (
    <Box>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="green"
        size="lg"
        width="full"
        _hover={{
          transform: "translateY(-2px)",
          shadow: "md",
        }}
        transition="all 0.2s"
      >
        <HStack width="full" justify="space-between">
          <HStack>
            <Icon as={FiMic} />
            <Text>View Speaking Result</Text>
          </HStack>
          <Icon as={FiChevronRight} />
        </HStack>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderRadius="xl" overflow="hidden">
          <ModalHeader 
            borderBottom="1px" 
            borderColor="gray.100"
            bg="green.50"
            py={4}
          >
            <HStack>
              <Icon as={FiMic} color="green.500" />
              <Text color="green.700">Speaking Test Results</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py={6}>
            <SpeakingResultAnswer feedback={feedbackData} />
          </ModalBody>

          <ModalFooter 
            borderTop="1px" 
            borderColor="gray.100"
            bg="gray.50"
          >
            <Button
              variant="ghost"
              onClick={onClose}
              size="lg"
              _hover={{
                bg: "gray.100",
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
