import {
  Stack,
  Flex,
  Text,
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  IoSchoolSharp,
  IoAnalyticsSharp,
  IoPersonSharp,
  IoSpeedometerSharp,
} from "react-icons/io5";

type FeatureProps = {
  text: string;
  description: string;
  iconBg: string;
  icon?: ReactElement;
};

const DisplayFeature = ({ text, icon, iconBg, description }: FeatureProps) => {
  return (
    <Box 
      p={6} 
      rounded="xl" 
      borderWidth="1px" 
      borderColor="gray.100"
      bg="white"
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-4px)",
        shadow: "lg",
        borderColor: "teal.200",
      }}
    >
      <Stack spacing={4}>
        <Flex align="center" gap={4}>
          <Flex
            w={12}
            h={12}
            align="center"
            justify="center"
            rounded="xl"
            bg={iconBg}
            shadow="md"
          >
            {icon}
          </Flex>
          <Text fontWeight={700} fontSize="lg" color="gray.900">
            {text}
          </Text>
        </Flex>
        <Text color="gray.600" fontSize="md" pl={16} lineHeight="tall">
          {description}
        </Text>
      </Stack>
    </Box>
  );
};

export const Feature = () => {
  return (
    <Box as="section" py={20} bg="blue.50" id="features">
      <Container maxW="container.xl">
        <VStack spacing={12}>
          <VStack spacing={4} textAlign="center" maxW="3xl">
            <Text
              color="teal.500"
              fontWeight={600}
              fontSize="sm"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Why Choose Us
            </Text>
            <Heading 
              bgGradient="linear(to-r, teal.600, blue.500)"
              bgClip="text"
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="bold"
              lineHeight="shorter"
            >
              Master CELPIP with Our
              Advanced Learning Platform
            </Heading>
            <Text color="gray.600" fontSize="lg" lineHeight="tall">
              Our AI-powered platform combines cutting-edge technology with proven learning methods
              to help you achieve your desired CELPIP score.
            </Text>
          </VStack>

          <SimpleGrid 
            columns={{ base: 1, md: 2 }} 
            spacing={8} 
            width="full"
          >
            <DisplayFeature
              icon={
                <Icon as={IoSchoolSharp} color="white" w={6} h={6} />
              }
              iconBg="teal.500"
              text="Real CELPIP Practice Tests"
              description="Access comprehensive practice tests that mirror the actual CELPIP exam format and difficulty level."
            />
            <DisplayFeature
              icon={
                <Icon as={IoAnalyticsSharp} color="white" w={6} h={6} />
              }
              iconBg="blue.500"
              text="Smart Progress Tracking"
              description="Monitor your improvement with detailed analytics and personalized performance insights."
            />
            <DisplayFeature
              icon={
                <Icon as={IoSpeedometerSharp} color="white" w={6} h={6} />
              }
              iconBg="purple.500"
              text="Instant AI Feedback"
              description="Get immediate, detailed feedback on your Writing and Speaking responses powered by advanced AI technology."
            />
            <DisplayFeature
              icon={
                <Icon as={IoPersonSharp} color="white" w={6} h={6} />
              }
              iconBg="green.500"
              text="Expert Study Tips"
              description="Access proven strategies and tips from CELPIP experts to maximize your score in all test components."
            />
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};
