import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Text,
  List,
  ListItem,
  ListIcon,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
} from "@chakra-ui/react";
import { 
  FiArrowRight, 
  FiInfo, 
  FiHeadphones, 
  FiBook, 
  FiEdit, 
  FiMic 
} from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useMockTestList } from "../../hooks/use-mock-test-list";

export const StartMockTest: React.FC = () => {
  const { testNum } = useParams();
  const [testLink, setTestLink] = useState<string>("#");
  const { getNextStepHref } = useMockTestList();

  useEffect(() => {
    setTestLink(`${getNextStepHref("Start")}/${testNum}`);
    localStorage.setItem("isCompleteMockTest", "true");
  }, [getNextStepHref, testNum]);

  return (
    <Box minH="100vh" py={10} bg="gray.50">
      <Container maxW="container.lg">
        <Card
          bg="white"
          shadow="sm"
          borderRadius="xl"
          overflow="hidden"
        >
          <CardHeader
            bg="blue.50"
            borderBottom="1px"
            borderColor="blue.100"
            py={6}
          >
            <VStack spacing={4} align="start">
              <Heading size="lg" color="blue.700">
                Welcome to your CELPIP Practice Test
              </Heading>
              <Text color="blue.600">
                Follow these instructions to complete each section of the test
              </Text>
            </VStack>
          </CardHeader>

          <CardBody py={8}>
            <VStack align="stretch" spacing={8}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                <Box p={4} bg="purple.50" borderRadius="lg">
                  <HStack mb={3}>
                    <Icon as={FiHeadphones} color="purple.500" boxSize={5} />
                    <Text fontWeight="semibold" color="purple.700">Listening</Text>
                  </HStack>
                  <Text color="purple.600" fontSize="sm">
                    Test your ability to understand spoken English in everyday situations
                  </Text>
                </Box>

                <Box p={4} bg="blue.50" borderRadius="lg">
                  <HStack mb={3}>
                    <Icon as={FiBook} color="blue.500" boxSize={5} />
                    <Text fontWeight="semibold" color="blue.700">Reading</Text>
                  </HStack>
                  <Text color="blue.600" fontSize="sm">
                    Evaluate your English reading comprehension skills
                  </Text>
                </Box>

                <Box p={4} bg="teal.50" borderRadius="lg">
                  <HStack mb={3}>
                    <Icon as={FiEdit} color="teal.500" boxSize={5} />
                    <Text fontWeight="semibold" color="teal.700">Writing</Text>
                  </HStack>
                  <Text color="teal.600" fontSize="sm">
                    Practice your written English expression and composition
                  </Text>
                </Box>

                <Box p={4} bg="green.50" borderRadius="lg">
                  <HStack mb={3}>
                    <Icon as={FiMic} color="green.500" boxSize={5} />
                    <Text fontWeight="semibold" color="green.700">Speaking</Text>
                  </HStack>
                  <Text color="green.600" fontSize="sm">
                    Demonstrate your spoken English communication abilities
                  </Text>
                </Box>
              </SimpleGrid>

              <Box bg="gray.50" p={6} borderRadius="lg">
                <VStack align="start" spacing={4}>
                  <HStack color="gray.700">
                    <Icon as={FiInfo} />
                    <Text fontWeight="semibold">Important Instructions</Text>
                  </HStack>

                  <List spacing={3} color="gray.600">
                    <ListItem display="flex">
                      <ListIcon as={FiInfo} color="blue.500" mt={1} />
                      <Text>
                        You can navigate freely between sections and questions in this practice test
                        to help you familiarize yourself with the format and timing.
                      </Text>
                    </ListItem>

                    <ListItem display="flex">
                      <ListIcon as={FiInfo} color="blue.500" mt={1} />
                      <Text>
                        Each section closely replicates the official CELPIP test, though practice tests
                        may not include additional experimental questions.
                      </Text>
                    </ListItem>

                    <ListItem display="flex">
                      <ListIcon as={FiInfo} color="blue.500" mt={1} />
                      <Text>
                        Take the test in a quiet environment and time yourself to simulate real
                        test conditions effectively.
                      </Text>
                    </ListItem>
                  </List>
                </VStack>
              </Box>
            </VStack>
          </CardBody>

          <CardFooter
            borderTop="1px"
            borderColor="gray.100"
            bg="gray.50"
            justify="flex-end"
          >
            <Button
              rightIcon={<FiArrowRight />}
              colorScheme="blue"
              size="lg"
              as="a"
              href={testLink}
              _hover={{ transform: "translateX(4px)" }}
              transition="all 0.2s"
            >
              Begin Practice Test
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </Box>
  );
};