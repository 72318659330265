import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  VStack,
  Badge,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { Practice } from "./questions/Practice";
import { DisplayQuestionContent } from "../../../components/DisplayQuestionContent";
import { addSpaceBeforeCaps } from "../../../utils/helperFuntions";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { startMockTestQuestionsAction } from "../../../redux/slice/mock-test/mockTestSlice";
import {
  QuestionBody,
  QuestionData,
  ReadingQuestionTypes,
} from "../../../types/reading-types";
import { CountdownTimer } from "../../../components/CountdownTimer";
import { ReadingAnswerKey } from "./answer/ReadingAnswerKey";
import { useParams } from "react-router-dom";
import { decryptString } from "../../../utils/encryption";
import { FiClock, FiBook, FiArrowRight } from "react-icons/fi";

export const ReadingTest: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const { isLoading } = useAppSelector((state) => state.mockTest);
  const [questions, setQuestions] = useState<ReadingQuestionTypes>();
  const { testNum } = useParams();
  const decryptedTestNum = decryptString(testNum?.toString() ?? "");

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        startMockTestQuestionsAction({
          type: "Reading",
          testNum: decryptedTestNum,
        }),
      );
      setQuestions(res.payload as ReadingQuestionTypes);
      if (res.payload) {
        const initialTime = parseInt(
          //@ts-ignore
          (res.payload as ReadingQuestionTypes).PracticeQuestion?.time,
        );
        setSecondsLeft(initialTime * 60);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const readingQsSections = [
    {
      id: 0,
      section: questions?.PracticeQuestion,
    },
    {
      id: 1,
      section: questions?.ReadingCorrespondence as unknown as QuestionData,
    },
    {
      id: 2,
      section: questions?.ReadingToApplyADiagram as unknown as QuestionData,
    },
    {
      id: 3,
      section: questions?.ReadingForInformation as unknown as QuestionData,
    },
    {
      id: 4,
      section: questions?.ReadingForViewpoints as unknown as QuestionData,
    },
  ];

  const setNextQuestion = useCallback(() => {
    setCurrentSection((prevSection) => prevSection + 1);
  }, []);

  const handleTimeUp = useCallback(() => {
    setNextQuestion();
  }, [setNextQuestion]);

  const setQuestionsData =
    (readingQsSections[currentSection]?.section as unknown as QuestionBody[]) ||
    [];

  const getCurrentQuestions: QuestionData = JSON.parse(
    setQuestionsData[0]?.data ?? "{}",
  );

  useEffect(() => {
    if (getCurrentQuestions.time) {
      setSecondsLeft(parseInt(getCurrentQuestions.time) * 60);
    }
  }, [currentSection, getCurrentQuestions.time]);

  if (isLoading) {
    return <Spinner />;
  }

  if (currentSection === readingQsSections.length) {
    return <ReadingAnswerKey />;
  }

  if (!setQuestionsData.length) {
    return (
      <>
        <Spinner />
        <Text>No questions available</Text>
      </>
    );
  }

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <Flex
          justify="space-between"
          align="center"
          bg="white"
          borderBottom="1px"
          borderColor="gray.200"
          p={4}
          shadow="sm"
        >
          <HStack spacing={4}>
            <Icon as={FiBook} color="blue.500" boxSize={5} />
            <VStack align="start" spacing={1}>
              <Heading size="md" color="gray.700" fontWeight="medium">
                Mock Test {decryptedTestNum}
              </Heading>
              <HStack>
                <Text color="gray.600" fontSize="sm">
                  {addSpaceBeforeCaps(getCurrentQuestions.questionsType)}
                </Text>
                <Badge colorScheme="blue" variant="subtle">
                  Section {currentSection + 1}/5
                </Badge>
              </HStack>
            </VStack>
          </HStack>

          <HStack spacing={6}>
            <HStack 
              bg="blue.50" 
              px={4} 
              py={2} 
              borderRadius="full"
              color="blue.600"
            >
              <Icon as={FiClock} />
              <CountdownTimer
                time={parseInt(getCurrentQuestions.time)}
                onTimeUp={handleTimeUp}
              />
            </HStack>
            <Button
              rightIcon={<FiArrowRight />}
              colorScheme="blue"
              onClick={setNextQuestion}
              size="sm"
              px={6}
              _hover={{ transform: "translateX(4px)" }}
              transition="all 0.2s"
            >
              Next
            </Button>
          </HStack>
        </Flex>
        <HStack justify="space-between">
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            borderLeft="1px #CCC solid"
            borderRight="1px #CCC solid"
            borderBottom="1px #CCC solid"
            minH="130vh"
            maxH="130vh"
            overflowY="auto"
          >
            <HStack>
              <Icon as={InfoIcon} color="blue.500" />
              <Heading size="sm" color="blue.600">
                Read the following message.
              </Heading>
            </HStack>

            {getCurrentQuestions.questionsType === "ReadingToApplyADiagram" ? (
              <Image
                alt={"Reading Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={`${getCurrentQuestions.bodyContent}`}
              />
            ) : (
              <DisplayQuestionContent
                content={getCurrentQuestions.bodyContent}
              />
            )}
          </VStack>
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            bg="#f7fafc"
            minH="130vh"
            maxH="130vh"
            borderBottom="1px #CCC solid"
            ml={-2}
            overflowY="auto"
          >
            <Practice data={getCurrentQuestions as QuestionData} />
          </VStack>
        </HStack>
        <HStack justify="space-between" mt={10}>
          {/* <Button as={"a"} href="/answer-key" colorScheme="blue">
            Answer Key
          </Button>
          <Button colorScheme="red">BACK</Button> */}
        </HStack>
      </Container>
    </Box>
  );
};
