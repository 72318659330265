import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Spinner,
  Text,
  VStack,
  Badge,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { UserResponse } from "./questions/UserResponse";
import {
  addSpaceBeforeCaps,
  listeningDefaultInstructionTwo,
} from "../../../utils/helperFuntions";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { startMockTestQuestionsAction } from "../../../redux/slice/mock-test/mockTestSlice";
import { CountdownTimer } from "../../../components/CountdownTimer";
import {
  ListeningQuestionTypes,
  ListeningQuestionData,
  ListeningQuestionBody,
  ListeningQuestionType,
} from "../../../types/listening-types";
import { MockQuestion } from "../../../types/reading-types";
import { ProblemSolvingSection } from "./questions/section/ProblemSolvingSection";
import { ProblemSolvingUserResponse } from "./questions/section/ProblemSolvingUserResponse";
import { DailyLifeConversationUserResponse } from "./questions/section/DailyLifeConversationUserResponse";
import { DailyLifeConversation } from "./questions/section/DailyLifeConversation";
import { ListeningToANewsItem } from "./questions/section/ListeningToANewsItem";
import { ListeningForInformation } from "./questions/section/ListeningForInformation";
import { ListeningForInformationUserResponse } from "./questions/section/ListeningForInformationUserResponse";
import { ListeningToANewsItemUserResponse } from "./questions/section/ListeningToANewsItemUserResponse";
import { ListeningToADiscussion } from "./questions/section/ListeningToADiscussion";
import { ListeningToADiscussionUserResponse } from "./questions/section/ListeningToADiscussionUserResponse";
import { ListeningToViewpoints } from "./questions/section/ListeningToViewpoints";
import { ListeningToViewpointsUserResponse } from "./questions/section/ListeningToViewpointsUserResponse";
import { ListeningAnswerKey } from "./answer/ListeningAnswerKey";
import { AudioPlayer } from "../../../components/AudioAndVideoPlayer";
import { useParams } from "react-router-dom";
import { decryptString } from "../../../utils/encryption";
import { FiClock, FiHeadphones, FiArrowRight } from "react-icons/fi";

const VStackStyle = {
  align: "start",
  spacing: "5",
  w: "full",
  p: "3",
  borderLeft: "1px #CCC solid",
  borderRight: "1px #CCC solid",
  borderBottom: "1px #CCC solid",
  minH: "90vh",
  maxH: "90vh",
  overflow: "auto",
};

export const ListeningTest: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  // eslint-disable-next-line no-unused-vars
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(190);
  const [stage, setStage] = useState<number>(0);
  const { isLoading } = useAppSelector((state) => state.mockTest);
  const [questions, setQuestions] = useState<ListeningQuestionTypes>();
  const { testNum } = useParams();
  const decryptedTestNum = decryptString(testNum?.toString() ?? "");

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        startMockTestQuestionsAction({
          type: "Listening",
          testNum: decryptedTestNum,
        }),
      );

      setQuestions(res.payload as ListeningQuestionTypes);
      if (res.payload) {
        const initialTime = parseInt(
          //@ts-ignore
          (res.payload as ListeningQuestionTypes).PracticeQuestion?.time,
        );
        setSecondsLeft(initialTime * 60);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const readingQsSections = [
    {
      id: 0,
      section: questions?.PracticeQuestion,
      questionType: "PracticeQuestion",
    },
    {
      id: 1,
      section:
        questions?.ListeningToProblemSolving as unknown as ListeningQuestionData,
      questionType: "ListeningToProblemSolving",
    },
    {
      id: 2,
      section:
        questions?.ListeningToADailyLifeConversation as unknown as ListeningQuestionData,
      questionType: "ListeningToADailyLifeConversation",
    },
    {
      id: 3,
      section:
        questions?.ListeningForInformation as unknown as ListeningQuestionData,
      questionType: "ListeningForInformation",
    },
    {
      id: 4,
      section:
        questions?.ListeningToANewsItem as unknown as ListeningQuestionData,
      questionType: "ListeningToANewsItem",
    },
    {
      id: 5,
      section:
        questions?.ListeningToADiscussion as unknown as ListeningQuestionData,
      questionType: "ListeningToADiscussion",
    },
    {
      id: 6,
      section:
        questions?.ListeningToViewpoints as unknown as ListeningQuestionData,
      questionType: "ListeningToViewpoints",
    },
  ];

  const setQuestionsData =
    (readingQsSections[currentSection]
      ?.section as unknown as ListeningQuestionBody[]) || [];

  const getCurrentQuestions: ListeningQuestionData = JSON.parse(
    setQuestionsData[0]?.data ?? "{}",
  );

  const setNextQuestion = useCallback(() => {
    setCurrentSection((prevSection) => {
      if (prevSection === 1 && stage < 14) {
        //ListeningToProblemSolving
        handleListeningToProblemSolving();
        return prevSection;
      } else if (prevSection === 2 && stage < 7) {
        //ListeningToADailyLifeConversation
        handleListeningToProblemSolving();
        return prevSection;
      } else if (prevSection === 3 && stage < 8) {
        //ListeningForInformation
        handleListeningToProblemSolving();
        return prevSection;
      } else if (prevSection === 4 && stage < 3) {
        //ListeningToANewsItem
        handleListeningToProblemSolving();
        return prevSection;
      } else if (prevSection === 5 && stage < 3) {
        //ListeningToANewsItem
        handleListeningToProblemSolving();
        return prevSection;
      } else if (prevSection === 6 && stage < 3) {
        //ListeningToANewsItem
        handleListeningToProblemSolving();
        return prevSection;
      } else {
        setStage(0);
        return prevSection + 1;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  useEffect(() => {
    setTimeLeft(190);
  }, [stage]);

  const handleListeningToProblemSolving = useCallback(() => {
    setStage((prevSection) => prevSection + 1);
    setTimeLeft(190);
  }, []);

  const handleTimeUp = useCallback(() => {
    setNextQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextQuestion]);

  if (isLoading) {
    return <Spinner />;
  }

 
  if (currentSection === readingQsSections.length) {
    return <ListeningAnswerKey />;
  }

  if (!setQuestionsData.length) {
    return (
      <>
        <Spinner />
        <Text>No questions available</Text>
      </>
    );
  }

  const testQuestionType = readingQsSections[currentSection]?.questionType || "";

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        minH="70vh"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <Flex
          justify="space-between"
          align="center"
          bg="white"
          borderBottom="1px"
          borderColor="gray.200"
          p={4}
          shadow="sm"
        >
          <HStack spacing={4}>
            <Icon as={FiHeadphones} color="purple.500" boxSize={5} />
            <VStack align="start" spacing={1}>
              <Heading size="md" color="gray.700" fontWeight="medium">
                Mock Test {decryptedTestNum}
              </Heading>
              <HStack>
                <Text color="gray.600" fontSize="sm">
                  {addSpaceBeforeCaps(testQuestionType)}
                </Text>
                <Badge colorScheme="purple" variant="subtle">
                  Section {currentSection + 1}/7
                </Badge>
              </HStack>
            </VStack>
          </HStack>

          <HStack spacing={6}>
            <HStack 
              bg="purple.50" 
              px={4} 
              py={2} 
              borderRadius="full"
              color="purple.600"
            >
              <Icon as={FiClock} />
              <CountdownTimer
                time={timeLeft}
                onTimeUp={handleTimeUp}
                timeType="Second"
                stage={stage}
              />
            </HStack>
            <Button
              rightIcon={<FiArrowRight />}
              colorScheme="purple"
              onClick={setNextQuestion}
              size="sm"
              px={6}
              _hover={{ transform: "translateX(4px)" }}
              transition="all 0.2s"
            >
              Next
            </Button>
          </HStack>
        </Flex>
        <HStack justify="space-between">
          <VStack
            {...(testQuestionType === "ListeningToANewsItem" ||
            testQuestionType === "ListeningToViewpoints" ||
            (testQuestionType === "ListeningToADiscussion" &&
              stage === 3)
              ? {}
              : VStackStyle)}
          >
            {/* PracticeQuestion Section */}
            {testQuestionType === "PracticeQuestion" && (
              <>
                <HStack>
                  <Icon as={InfoIcon} color="blue.500" />
                  <Heading size="sm" color="blue.600">
                    {listeningDefaultInstructionTwo}
                  </Heading>
                </HStack>
                <AudioPlayer src={getCurrentQuestions.bodyContent} />
              </>
            )}

            {/* ListeningToProblemSolving Section */}
            {testQuestionType ===
              "ListeningToProblemSolving" && (
              <ProblemSolvingSection
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
            {/* ListeningToADailyLifeConversation Section */}
            {testQuestionType ===
              "ListeningToADailyLifeConversation" && (
              <DailyLifeConversation
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
            {/* ListeningForInformation Section */}
            {testQuestionType ===
              "ListeningForInformation" && (
              <ListeningForInformation
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
            {/* ListeningToANewsItem Section */}
            {testQuestionType === "ListeningToANewsItem" && (
              <ListeningToANewsItem
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
            {/* ListeningToADiscussion Section */}
            {testQuestionType === "ListeningToADiscussion" && (
              <ListeningToADiscussion
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
            {/* ListeningToViewpoints Section */}
            {testQuestionType === "ListeningToViewpoints" && (
              <ListeningToViewpoints
                questionData={getCurrentQuestions}
                stage={stage}
              />
            )}
          </VStack>
          {/* *************************************USER RESPONSE SECTION */}
          {/* PracticeQuestion Section */}
          {testQuestionType === "PracticeQuestion" && (
            <VStack
              align="start"
              spacing={5}
              w="full"
              p={3}
              bg="#f7fafc"
              minH="90vh"
              maxH="90vh"
              borderBottom="1px #CCC solid"
              ml={-2}
              overflowY="auto"
            >
              <UserResponse
                questions={getCurrentQuestions.questions[0] as MockQuestion}
                questionType={
                  testQuestionType as ListeningQuestionType
                }
              />
            </VStack>
          )}
          {/* ListeningToProblemSolving Section */}
          {testQuestionType ===
            "ListeningToProblemSolving" && (
            <ProblemSolvingUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}
          {/* ListeningToADailyLifeConversation Section */}
          {testQuestionType ===
            "ListeningToADailyLifeConversation" && (
            <DailyLifeConversationUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}
          {/* ListeningForInformation Section */}
          {testQuestionType === "ListeningForInformation" && (
            <ListeningForInformationUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}
          {/* ListeningToANewsItem Section */}
          {testQuestionType === "ListeningToANewsItem" && (
            <ListeningToANewsItemUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}
          {/* ListeningToADiscussion Section */}
          {testQuestionType === "ListeningToADiscussion" && (
            <ListeningToADiscussionUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}

          {/* ListeningToViewpoints Section */}
          {testQuestionType === "ListeningToViewpoints" && (
            <ListeningToViewpointsUserResponse
              questionData={getCurrentQuestions}
              stage={stage}
            />
          )}
        </HStack>
        <HStack justify="space-between" mt={10}>
          {/* <Button as={"a"} href="/answer-key" colorScheme="blue">
            Answer Key
          </Button> */}
          {/* <Button colorScheme="red">BACK</Button> */}
        </HStack>
      </Container>
    </Box>
  );
};
