import {
  Box,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  HStack,
  Icon,
  Text,
  Card,
  CardHeader,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
} from "@chakra-ui/react";
import { FiUsers, FiUserCheck, FiUserX, FiClock } from "react-icons/fi";
import { useStudents } from "../../../hooks/use-students";
import { Student } from "../../../redux/slice/student/studentSlice";
import { RemoveStudentModal } from "./edit/RemoveStudentModal";
import { AddNewStudentModal } from "./edit/AddNewStudentModal";

export const AllStudents = () => {
  const { isLoading, students } = useStudents("all");
  const sortedStudents = [...(students || [])].sort((a, b) => b.id - a.id);

  const activeStudents = sortedStudents.filter(s => s.isActive).length;
  const expiredPlans = sortedStudents.filter(s => {
    const expDate = new Date(s.exp_date);
    return expDate < new Date();
  }).length;

  if (isLoading) {
    return (
      <Flex h="100vh" align="center" justify="center">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <Stack spacing={6}>
      <Flex align="center" justify="space-between">
        <HStack>
          <Icon as={FiUsers} boxSize={6} color="blue.500" />
          <Heading size="lg">Student Management</Heading>
        </HStack>
        <AddNewStudentModal />
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Total Students</StatLabel>
              <HStack>
                <Icon as={FiUsers} color="blue.500" />
                <StatNumber>{sortedStudents.length}</StatNumber>
              </HStack>
            </Stat>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Active Students</StatLabel>
              <HStack>
                <Icon as={FiUserCheck} color="green.500" />
                <StatNumber>{activeStudents}</StatNumber>
              </HStack>
            </Stat>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Inactive Students</StatLabel>
              <HStack>
                <Icon as={FiUserX} color="red.500" />
                <StatNumber>{sortedStudents.length - activeStudents}</StatNumber>
              </HStack>
            </Stat>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Expired Plans</StatLabel>
              <HStack>
                <Icon as={FiClock} color="orange.500" />
                <StatNumber>{expiredPlans}</StatNumber>
              </HStack>
            </Stat>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Card>
        <CardHeader>
          <Heading size="md">Student List</Heading>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple">
              <Thead bg="gray.50">
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                  <Th>Plan Status</Th>
                  <Th>Expiry Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedStudents.map((student: Student) => {
                  const isExpired = new Date(student.exp_date) < new Date();
                  const expiryDate = new Date(student.exp_date);
                  const daysLeft = Math.ceil((expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24));

                  return (
                    <Tr key={student.id}>
                      <Td>ST-{student.id}</Td>
                      <Td>
                        <Text fontWeight="medium">
                          {student.firstName} {student.lastName}
                        </Text>
                      </Td>
                      <Td>{student.email}</Td>
                      <Td>
                        <Badge
                          colorScheme={student.isActive ? "green" : "red"}
                          variant="subtle"
                          px={2}
                          py={1}
                          borderRadius="full"
                        >
                          {student.isActive ? "Active" : "Inactive"}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={student.checkoutSession ? "blue" : "gray"}
                          variant="subtle"
                          px={2}
                          py={1}
                          borderRadius="full"
                        >
                          {student.checkoutSession ? "Subscribed" : "Not Subscribed"}
                        </Badge>
                      </Td>
                      <Td>
                        <HStack>
                          <Badge
                            colorScheme={isExpired ? "red" : daysLeft <= 7 ? "orange" : "green"}
                            variant="subtle"
                            px={2}
                            py={1}
                            borderRadius="full"
                          >
                            {isExpired ? "Expired" : `${daysLeft} days left`}
                          </Badge>
                          <Text fontSize="sm" color="gray.600">
                            {expiryDate.toLocaleDateString()}
                          </Text>
                        </HStack>
                      </Td>
                      <Td>
                        <RemoveStudentModal student={student} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
    </Stack>
  );
};
