import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { Logo } from "../../components/Logo";
import { FaCheckCircle } from "react-icons/fa";

export const Hero = () => {

  return (
    <Box bg="white" position="relative" overflow="hidden">
      <Container maxW="container.xl" py={{ base: 20, md: 32 }}>
        <Stack
          as={Box}
          spacing={{ base: 10, md: 16 }}
          textAlign="center"
          position="relative"
          zIndex={1}
        >
          <Stack spacing={6}>
            <Heading
              fontWeight={800}
              fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
              lineHeight={"110%"}
              bgGradient="linear(to-r, teal.600, blue.500)"
              bgClip="text"
              textShadow="0 2px 4px rgba(0,0,0,0.1)"
            >
              Master Your CELPIP Test with
              <br />
              <Logo />
            </Heading>
            <Text 
              color={"gray.700"} 
              fontSize={{ base: "lg", md: "2xl" }}
              maxW="3xl"
              mx="auto"
              lineHeight="tall"
            >
              <Text as="span" fontWeight="bold" color="teal.600">
                Start for FREE
              </Text>{" "}
              and join thousands of successful test-takers.
              <br /> 
              Your journey to CELPIP success starts here.
            </Text>
          </Stack>

        
          <Box>
            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={8}
              justify="center"
              fontSize="sm"
              color="gray.600"
            >
              <HStack spacing={2}>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text>AI-Scoring Writing and Speaking sections</Text>
              </HStack>
              <HStack spacing={2}>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text>Real CELPIP Format</Text>
              </HStack>
              <HStack spacing={2}>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text>Instant Feedback</Text>
              </HStack>
            </Stack>
          </Box>
          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={4}
            justify="center"
            align="center"
          >
            <Button
              as="a"
              href="/sign-in"
              colorScheme="teal"
              size="lg"
              rounded="full"
              px={10}
              py={7}
              fontSize="xl"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "xl",
              }}
              transition="all 0.3s"
            >
              Start Free Practice
            </Button>
            <Button
              as="a"
              href="#features"
              variant="outline"
              colorScheme="teal"
              size="lg"
              rounded="full"
              px={10}
              py={7}
              fontSize="xl"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
                bg: "teal.50",
              }}
              transition="all 0.3s"
            >
              Explore Features
            </Button>
          </Stack>

        </Stack>
      </Container>

      {/* Background decoration */}
      <Box
        position="absolute"
        top="-10%"
        right="-10%"
        width="500px"
        height="500px"
        bg="teal.50"
        borderRadius="full"
        filter="blur(70px)"
        opacity="0.4"
        zIndex={0}
      />
      <Box
        position="absolute"
        bottom="-10%"
        left="-10%"
        width="500px"
        height="500px"
        bg="blue.50"
        borderRadius="full"
        filter="blur(70px)"
        opacity="0.4"
        zIndex={0}
      />
    </Box>
  );
};

