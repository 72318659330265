import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Spinner,
  Card,
  CardBody,
  Icon,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useUser } from "../../hooks/use-user";
import {
  useAppSelector,
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import { decryptString } from "../../utils/encryption";
import { TestResult } from "../../types/test-result-type";
import { ListeningResultPreview } from "./ListeningResultPreview";
import { ReadingResultPreview } from "./ReadingResultPreview";
import { WritingResultPreview } from "./WritingResultPreview";
import { SpeakingResultPreview } from "./SpeakingResultPreview";
import { FinalResultPreview } from "./FinalResultPreview";
import {
  FiHeadphones,
  FiBook,
  FiEdit,
  FiMic,
  FiAlertCircle,
} from "react-icons/fi";
import {
  getMockTestResultAction,
  ResultPayload,
} from "../../redux/slice/mock-test/mockTestResultSlice";

const getTestDataByType = (
  data: TestResult[],
  testType: string,
): TestResult[] => {
  return data?.filter((item) => item?.testType === testType) ?? [];
};

const ResultSection = ({
  title,
  icon,
  color,
  children,
}: {
  title: string;
  icon: React.ElementType;
  color: string;
  children: React.ReactNode;
}) => (
  <Card variant="outline" borderColor="gray.200" shadow="sm">
    <CardBody>
      <VStack spacing={4} align="stretch">
        <HStack spacing={3}>
          <Icon as={icon} boxSize={5} color={`${color}.500`} />
          <Heading size="md" color={`${color}.700`}>
            {title}
          </Heading>
        </HStack>
        <Divider />
        {children}
      </VStack>
    </CardBody>
  </Card>
);

const NoResultMessage = ({ type }: { type: string }) => (
  <HStack spacing={2} justify="center" py={4}>
    <Icon as={FiAlertCircle} color="blue.500" />
    <Text color="blue.600">No {type} test result available</Text>
  </HStack>
);

export const CompleteTestResult: React.FC = () => {
  const user = useUser();
  const { isLoading } = useAppSelector(
    (state: { mockTestResult: any }) => state.mockTestResult,
  );
  const [mockTestResults, setMockTestResults] = useState<
    ResultPayload[] | null
  >(null);
  const { testNum } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        getMockTestResultAction({
          testNum: decryptString(testNum?.toString() ?? ""),
          userID: user.id.toString(),
        }),
      );
      setMockTestResults(res.payload);
    };
    fetchData();
  }, [dispatch, testNum, user.id]);

  if (isLoading) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="gray.50"
      >
        <Spinner size="xl" color="blue.500" thickness="4px" speed="0.65s" />
      </Box>
    );
  }

  const results = {
    listening: getTestDataByType(
      mockTestResults as unknown as TestResult[],
      "Listening",
    ),
    reading: getTestDataByType(
      mockTestResults as unknown as TestResult[],
      "Reading",
    ),
    writing: getTestDataByType(
      mockTestResults as unknown as TestResult[],
      "Writing",
    ),
    speaking: getTestDataByType(
      mockTestResults as unknown as TestResult[],
      "Speaking",
    ),
  };

  return (
    <Box minH="100vh" py={10} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={8}>
          <Card w="full" bg="white" borderRadius="xl" shadow="sm">
            <CardBody>
              <VStack spacing={4}>
                <Heading
                  size="lg"
                  bgGradient="linear(to-r, blue.600, teal.600)"
                  bgClip="text"
                >
                  Complete Mock Test Results
                </Heading>
                <Text color="gray.600">
                  Review your performance in each section of the test
                </Text>
              </VStack>
            </CardBody>
          </Card>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} w="full">
            <ResultSection title="Listening" icon={FiHeadphones} color="purple">
              {results.listening.length > 0 ? (
                <ListeningResultPreview result={results.listening[0]} />
              ) : (
                <NoResultMessage type="listening" />
              )}
            </ResultSection>

            <ResultSection title="Reading" icon={FiBook} color="blue">
              {results.reading.length > 0 ? (
                <ReadingResultPreview result={results.reading[0]} />
              ) : (
                <NoResultMessage type="reading" />
              )}
            </ResultSection>

            <ResultSection title="Writing" icon={FiEdit} color="teal">
              {results.writing.length > 0 ? (
                <WritingResultPreview result={results.writing[0]} />
              ) : (
                <NoResultMessage type="writing" />
              )}
            </ResultSection>

            <ResultSection title="Speaking" icon={FiMic} color="green">
              {results.speaking.length > 0 ? (
                <SpeakingResultPreview result={results.speaking[0]} />
              ) : (
                <NoResultMessage type="speaking" />
              )}
            </ResultSection>
          </SimpleGrid>

          <Card w="full" variant="outline" borderColor="blue.200" shadow="sm">
            <CardBody>
              <FinalResultPreview
                listeningResult={results.listening[0]}
                readingResult={results.reading[0]}
                writingResult={results.writing[0]}
                speakingResult={results.speaking[0]}
              />
            </CardBody>
          </Card>
        </VStack>
      </Container>
    </Box>
  );
};
