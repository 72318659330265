import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Text,
  List,
  ListItem,
  ListIcon,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { FiClock, FiArrowRight, FiArrowLeft, FiInfo, FiBook } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

export const ReadingTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();

  return (
    <Box minH="100vh" py={10} bg="gray.50">
      <Container maxW="container.md">
        <Card 
          bg="white" 
          shadow="sm"
          borderRadius="xl"
          overflow="hidden"
        >
          <CardHeader 
            bg="blue.50" 
            borderBottom="1px" 
            borderColor="blue.100"
          >
            <HStack justify="space-between" align="center">
              <Heading size="md" color="blue.700">
                Reading Test Instructions
              </Heading>
              <Icon as={FiBook} color="blue.500" boxSize={5} />
            </HStack>
          </CardHeader>

          <CardBody py={6}>
            <VStack align="stretch" spacing={6}>
              <Box>
                <HStack mb={4} color="blue.600">
                  <Icon as={FiClock} />
                  <Text fontWeight="medium">Time Limit: 60 minutes</Text>
                </HStack>

                <List spacing={4} color="gray.700">
                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="blue.500" mt={1} />
                    <Text>
                      On the official test, once you leave a page, you cannot go back
                      to it to change your answers. However, in this practice test,
                      you can review and modify your answers.
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="blue.500" mt={1} />
                    <Text>
                      Watch the timer in the top right corner to ensure you complete
                      the Reading Test before time runs out.
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={FiInfo} color="blue.500" mt={1} />
                    <Text>
                      This Reading Test matches the official test format. Note that the 
                      official test may be slightly longer as it might include additional 
                      questions for research and development purposes.
                    </Text>
                  </ListItem>
                </List>
              </Box>
            </VStack>
          </CardBody>

          <CardFooter 
            borderTop="1px" 
            borderColor="gray.100"
            bg="gray.50"
            justify="space-between"
          >
            <Button
              leftIcon={<FiArrowLeft />}
              variant="ghost"
              onClick={() => navigate(-1)}
              _hover={{ transform: "translateX(-4px)" }}
              transition="all 0.2s"
            >
              Back
            </Button>
            <Button
              rightIcon={<FiArrowRight />}
              colorScheme="blue"
              as="a"
              href={`/reading-test/${testNum}`}
              _hover={{ transform: "translateX(4px)" }}
              transition="all 0.2s"
            >
              Start Test
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </Box>
  );
};
