import { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Heading,
  SimpleGrid,
  Button,
  Container,
  useDisclosure,
  AlertIcon,
  Alert,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Icon,
  Text,
  HStack,
} from "@chakra-ui/react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import { getMockTestQuestionsAction } from "../../redux/slice/mock-test/mockTestsViewSlice";
import { WritingQuestion } from "../../types/writing-types";
import { encryptString } from "../../utils/encryption";
import { useUser } from "../../hooks/use-user";
import { isDateExpired } from "../../utils/helperFuntions";
import { CompleteTestButton } from "../../components/ CompleteTestButton";
import { 
  FaHeadphones, 
  FaBook, 
  FaPencilAlt, 
  FaMicrophone,
  FaLock 
} from "react-icons/fa";
import { IconType } from "react-icons";

export const MockTest = () => {
  const user = useUser();
  const { isLoading } = useAppSelector((state) => state.mockTestView);
  const [questions, setQuestions] = useState<WritingQuestion[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.setItem("isCompleteMockTest", "false");
    const fetchData = async () => {
      const res = await dispatch(getMockTestQuestionsAction(user.id));
      setQuestions(res.payload);
    };
    fetchData();
  }, [dispatch, user.id]);

  const getHighestTestNum = (questions: WritingQuestion[]) => {
    return Math.max(...questions?.map((q) => Number(q.testNum)));
  };

  const handleExpModal = () => {
    if (isDateExpired(user?.exp_date)) {
      onOpen();
      return true;
    }
    return false;
  };

  const TestButton = ({ type, testNum }: { type: string; testNum: number }) => {
    const icons: Record<string, IconType> = {
      Listening: FaHeadphones,
      Reading: FaBook,
      Writing: FaPencilAlt,
      Speaking: FaMicrophone,
    };

    const colors: Record<string, string> = {
      Listening: "purple",
      Reading: "blue",
      Writing: "teal",
      Speaking: "green",
    };

    const testExists = questions.some(
      (q) => Number(q.testNum) === testNum && q.type === type
    );

    const isLocked = isDateExpired(user?.exp_date) && testNum !== 1;
    const encryptedTestNum = encryptString(testNum.toString());

    if (!testExists) {
      return (
        <Button
          isDisabled
          variant="outline"
          colorScheme="gray"
          height="52px"
          w="full"
          opacity={0.5}
        >
          <HStack spacing={3}>
            <Icon as={icons[type]} boxSize={5} />
            <Text>{type} Not Set</Text>
          </HStack>
        </Button>
      );
    }

    return (
      <Button
        as="a"
        href={isLocked ? "#" : `/${type.toLowerCase()}-test-instructions/${encryptedTestNum}`}
        variant={isLocked ? "outline" : "solid"}
        colorScheme={colors[type]}
        height="52px"
        w="full"
        position="relative"
        _hover={{
          transform: "translateY(-2px)",
          shadow: "md",
        }}
        transition="all 0.2s"
      >
        <HStack spacing={3}>
          <Icon as={icons[type]} boxSize={5} />
          <Text>{type} Test</Text>
        </HStack>
        {isLocked && (
          <Icon
            as={FaLock}
            position="absolute"
            top={3}
            right={3}
            boxSize={4}
            color="gray.400"
          />
        )}
      </Button>
    );
  };

  if (isLoading) {
    return (
      <Box minH="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="teal.500" thickness="4px" />
      </Box>
    );
  }

  const highestTestNum = getHighestTestNum(questions);

  return (
    <Box minH="100vh" py={8} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Box bg="white" p={8} borderRadius="xl" shadow="sm">
            <Heading 
              fontSize={{ base: "2xl", md: "3xl" }} 
              color="blue.700"
              bgGradient="linear(to-r, blue.700, teal.500)"
              bgClip="text"
            >
              Start Mock Test
            </Heading>

            {isDateExpired(user?.exp_date) && (
              <Alert
                status="warning"
                mt={6}
                borderRadius="lg"
                variant="subtle"
              >
                <AlertIcon />
                <Text>
                  Your subscription has expired. You can only practice using one
                  free mock test. Please upgrade your plan to access all mock tests.
                </Text>
              </Alert>
            )}
          </Box>

          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
            {Array.from({ length: highestTestNum }, (_, i) => (
              <Card 
                key={i} 
                overflow="hidden"
                variant="outline"
                borderColor="gray.200"
                borderRadius="lg"
                transition="all 0.2s"
                _hover={{ 
                  shadow: "md",
                  borderColor: "blue.200"
                }}
              >
                <CardHeader 
                  bg="gray.50" 
                  py={4}
                  borderBottom="1px" 
                  borderColor="gray.200"
                >
                  <Heading 
                    size="md" 
                    color="gray.700"
                    display="flex"
                    alignItems="center"
                    gap={2}
                  >
                    Practice Test {i + 1}
                  </Heading>
                </CardHeader>
                <CardBody p={6}>
                  <VStack spacing={6}>
                    <SimpleGrid 
                      columns={{ base: 1, sm: 2 }} 
                      spacing={4} 
                      w="full"
                    >
                      {["Listening", "Reading", "Writing", "Speaking"].map((type) => (
                        <TestButton key={type} type={type} testNum={i + 1} />
                      ))}
                    </SimpleGrid>

                    {(isDateExpired(user?.exp_date) && i + 1 === 1) ||
                    !isDateExpired(user?.exp_date) ? (
                      <CompleteTestButton
                        testNum={i + 1}
                        questions={questions}
                      />
                    ) : (
                      <Button
                        as="a"
                        href="/package-plan"
                        colorScheme="blue"
                        size="lg"
                        width="full"
                        height="52px"
                        leftIcon={<Icon as={FaLock} />}
                        _hover={{ 
                          transform: "translateY(-2px)",
                          shadow: "md"
                        }}
                        transition="all 0.2s"
                      >
                        Upgrade to Unlock
                      </Button>
                    )}
                  </VStack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="xl">
          <ModalHeader>Upgrade Your Plan</ModalHeader>
          <ModalBody>
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              Your subscription has expired. To access this feature, please
              upgrade your plan.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button 
              variant="ghost" 
              mr={3} 
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button 
              colorScheme="blue"
              as="a" 
              href="/package-plan"
              _hover={{ transform: "translateY(-2px)" }}
              transition="all 0.2s"
            >
              Upgrade Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
