import {
  Text,
  Box,
  Container,
  Heading,
  VStack,
  SimpleGrid,
  Circle,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { Logo } from "../../components/Logo";
import { 
  FaUserPlus, 
  FaClipboardCheck, 
  FaChartLine, 
  FaTrophy 
} from "react-icons/fa";

type StepProps = {
  title: string;
  description: string;
  icon: React.ElementType;
  stepNumber: number;
};

const Step = ({ title, description, icon, stepNumber }: StepProps) => (
  <HStack 
    spacing={4} 
    align="start"
    bg="white"
    p={6}
    rounded="xl"
    shadow="sm"
    borderWidth="1px"
    borderColor="gray.100"
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-4px)",
      shadow: "md",
      borderColor: "teal.200",
    }}
  >
    <Circle
      size="50px"
      bg="teal.500"
      color="white"
      position="relative"
    >
      <Icon as={icon} w={5} h={5} />
      <Circle
        size="24px"
        bg="blue.500"
        color="white"
        fontSize="xs"
        fontWeight="bold"
        position="absolute"
        top="-8px"
        right="-8px"
        border="2px solid white"
      >
        {stepNumber}
      </Circle>
    </Circle>
    <VStack align="start" spacing={2}>
      <Text fontSize="lg" fontWeight="bold" color="gray.900">
        {title}
      </Text>
      <Text color="gray.600" fontSize="md">
        {description}
      </Text>
    </VStack>
  </HStack>
);

export const Steps = () => {
  const steps = [
    {
      title: "Create Account",
      description: "Sign up in seconds and choose your study plan",
      icon: FaUserPlus,
    },
    {
      title: "Practice Tests",
      description: "Access AI-powered practice tests and get instant feedback",
      icon: FaClipboardCheck,
    },
    {
      title: "Track Progress",
      description: "Monitor your improvement with detailed analytics",
      icon: FaChartLine,
    },
    {
      title: "Achieve Success",
      description: "Reach your target CELPIP score with confidence",
      icon: FaTrophy,
    },
  ];

  return (
    <Box as="section" py={20} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          <VStack spacing={4} textAlign="center">
            <Heading
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              bgGradient="linear(to-r, teal.600, blue.500)"
              bgClip="text"
            >
              How <Logo size={39} /> Works
            </Heading>
            <Text 
              color="gray.600" 
              fontSize="lg"
              maxW="2xl"
            >
              Follow these simple steps to start your journey towards CELPIP success
            </Text>
          </VStack>

          <SimpleGrid 
            columns={{ base: 1, md: 2 }} 
            spacing={8}
            width="full"
          >
            {steps.map((step, index) => (
              <Step
                key={step.title}
                title={step.title}
                description={step.description}
                icon={step.icon}
                stepNumber={index + 1}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};
