import { Box } from "@chakra-ui/react";
import { Hero } from "./Hero";
import { Feature } from "./Feature";
import { PackagePlans } from "./PackagePlans";
import { Steps } from "./Steps";
import { Testimonials } from "./Testimonials";
import { FAQS } from "./FAQS";


export const Home = () => {
  return (
    <Box width={"100%"} h={"100%"}>
      <Hero />
      <Feature />
      <Steps />
      <PackagePlans />
      <Testimonials />
      <FAQS />
    </Box>
  );
};
