import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Progress,
  IconButton,
  Image,
  VStack,
  HStack,
  Icon,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { FiMic, FiPlay, FiUser, FiTarget, FiCheckCircle, FiArrowRight } from "react-icons/fi";
import { useUser } from "../../hooks/use-user";

export const StudentDashboard = () => {
  const user = useUser();
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [recordingProgress, setRecordingProgress] = useState(0);
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const playbackIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleStartRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording");
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    const audioChunks: Blob[] = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
      setAudioBlob(audioBlob);
      stream.getTracks().forEach((track) => track.stop());
      setRecordingProgress(0);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);

    // Start recording progress
    recordingIntervalRef.current = setInterval(() => {
      setRecordingProgress((prev) => prev + 1);
    }, 1000); // Update every second
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(recordingIntervalRef.current!);
      setRecordingProgress(0); // Reset progress after recording stops
    }
  };

  const handlePlayRecording = () => {
    if (audioBlob) {
      const audioURL = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(audioURL);
      audioRef.current.play();

      // Initialize playback progress
      setPlaybackProgress(0);

      playbackIntervalRef.current = setInterval(() => {
        if (audioRef.current) {
          const progress =
            (audioRef.current.currentTime / audioRef.current.duration) * 100;
          setPlaybackProgress(progress);

          // Clear interval when audio ends
          if (progress >= 100) {
            clearInterval(playbackIntervalRef.current!);
          }
        }
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      // Clean up intervals on component unmount
      if (recordingIntervalRef.current) clearInterval(recordingIntervalRef.current);
      if (playbackIntervalRef.current) clearInterval(playbackIntervalRef.current);
    };
  }, []);

  return (
    <Box bg="gray.50" minH="100vh" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={8}>
          {/* Welcome Card */}
          <Card
            w="full"
            overflow="hidden"
            variant="outline"
            borderColor="blue.100"
            borderRadius="xl"
            _hover={{ shadow: "md" }}
            transition="all 0.2s"
          >
            <CardBody p={0}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box 
                  bg="blue.50" 
                  p={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image 
                    src="/images/student.png" 
                    w="200px"
                    h="200px"
                    objectFit="contain"
                  />
                </Box>
                <VStack 
                  align="start" 
                  p={8} 
                  spacing={4}
                  bg="white"
                >
                  <HStack spacing={3}>
                    <Icon as={FiUser} boxSize={6} color="blue.500" />
                    <VStack align="start" spacing={1}>
                      <Text color="gray.600" fontSize="lg">
                        Welcome back,
                      </Text>
                      <Heading size="lg" color="blue.700">
                        {`${user.firstName} ${user.lastName}`}
                      </Heading>
                    </VStack>
                  </HStack>
                  <Text color="gray.600" fontSize="md">
                    Ready to continue your CELPIP preparation journey?
                  </Text>
                  <Button colorScheme="blue" size="sm" as={"a"} href="/mock-test">
                    Start Test Now <Icon as={FiArrowRight} />
                  </Button>
                </VStack>
              </SimpleGrid>
            </CardBody>
          </Card>

          {/* Stats Cards */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} w="full">
            <Card p={6}>
              <CardBody>
                <VStack spacing={4} align="start">
                  <HStack spacing={3}>
                    <Icon as={FiCheckCircle} boxSize={5} color="blue.500" />
                    <Heading size="md">Progress</Heading>
                  </HStack>
                  <Box w="full">
                    <HStack justify="space-between" mb={2}>
                      <Text color="gray.600">Mock Tests Completed</Text>
                      <Text fontWeight="bold" color="blue.600">...</Text>
                    </HStack>
                    <Progress 
                      value={88} 
                      size="sm" 
                      colorScheme="blue" 
                      borderRadius="full" 
                    />
                  </Box>
                </VStack>
              </CardBody>
            </Card>

            <Card p={6}>
              <CardBody>
                <VStack spacing={4} align="start">
                  <HStack spacing={3}>
                    <Icon as={FiTarget} boxSize={5} color="teal.500" />
                    <Heading size="md">Target Score</Heading>
                  </HStack>
                  <Text 
                    fontSize="4xl" 
                    fontWeight="bold" 
                    color="teal.500"
                  >
                    9+
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          </SimpleGrid>

          {/* Microphone Test Card */}
          <Card w="full" p={6}>
            <CardBody>
              <VStack spacing={6}>
                <HStack spacing={3}>
                  <Icon as={FiMic} boxSize={5} color="purple.500" />
                  <Heading size="md">Microphone Test</Heading>
                </HStack>

                <HStack spacing={4}>
                  {isRecording ? (
                    <Button
                      colorScheme="red"
                      size="lg"
                      onClick={handleStopRecording}
                      leftIcon={<Icon as={FiMic} />}
                    >
                      Stop Recording
                    </Button>
                  ) : (
                    <IconButton
                      icon={<Icon as={FiMic} boxSize={6} />}
                      aria-label="Record Audio"
                      colorScheme="blue"
                      size="lg"
                      isRound
                      onClick={handleStartRecording}
                    />
                  )}
                  <IconButton
                    icon={<Icon as={FiPlay} boxSize={6} />}
                    aria-label="Play Audio"
                    colorScheme="green"
                    size="lg"
                    isRound
                    onClick={handlePlayRecording}
                    isDisabled={!audioBlob}
                  />
                </HStack>

                {isRecording && (
                  <Box w="full">
                    <Text mb={2} color="gray.600">Recording in progress...</Text>
                    <Progress 
                      value={recordingProgress} 
                      max={10} 
                      size="sm"
                      colorScheme="red" 
                      borderRadius="full"
                      hasStripe
                      isAnimated
                    />
                  </Box>
                )}

                {audioBlob && !isRecording && (
                  <Box w="full">
                    <Text mb={2} color="gray.600">Playback progress</Text>
                    <Progress 
                      value={playbackProgress} 
                      size="sm"
                      colorScheme="green" 
                      borderRadius="full"
                    />
                  </Box>
                )}
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    </Box>
  );
};
