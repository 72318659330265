import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { planPrice } from "../../../utils/helperFuntions";
import { useEffect } from "react";

type PackageCardProps = {
  title: string;
  description: string;
  duration: number;
  onClick: () => void;
  isPopular?: boolean;
  isSelected?: boolean;
  bgColor: string;
  defaultSelected?: boolean;
};

export const PackageCard: React.FC<PackageCardProps> = ({
  title,
  description,
  duration,
  onClick,
  isPopular,
  isSelected,
  bgColor,
  defaultSelected,
}) => {
  useEffect(() => {
    if (defaultSelected) {
      onClick();
    }
  }, []);

  const price = planPrice(duration);

  return (
    <Box
      as="button"
      onClick={onClick}
      borderWidth={isSelected ? "2px" : ""}
      borderColor={isSelected ? "purple.400" : ""}
      boxShadow={isSelected ? "xl" : "lg"}
      borderRadius="md"
      p={6}
      bgColor={bgColor}
      position="relative"
      textAlign="center"
      width="100%"
      transition="all 0.2s"
      _hover={{
        transform: "translateY(-4px)",
        shadow: "xl",
      }}
    >
      {isPopular && (
        <Box
          position="absolute"
          top="-10px"
          right="10px"
          bg="#FFD700" // Gold color to highlight popular
          color="black"
          px={3}
          py={1}
          borderRadius="md"
          fontSize="sm"
          fontWeight="bold"
        >
          Most Popular
        </Box>
      )}
      <Text fontWeight="bold" fontSize="lg">
        {title}
      </Text>
      {duration && (
        <Heading size="xl"  mb={2}>
       {duration} days
      </Heading>
      )}

      <Text>
        <span
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          style={{textAlign:"left"}}
        />
      </Text>
      <Text fontSize="2xl" fontWeight="bold" mt={4}>
        ${price}
      </Text>
      <Button mt={4} colorScheme="teal" width={"full"}>
        PURCHASE
      </Button>
    </Box>
  );
};
