import React, { FC } from "react";
import { TestResult } from "../../types/test-result-type";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { WritingMockTestData } from "../../redux/slice/mock-test/writingTestSlice";
import { WritingResultAnswer } from "../test/writing/WritingResultAnswer";
import { FiEdit, FiChevronRight } from "react-icons/fi";

type WritingResultPreviewProps = {
  result: TestResult;
};

export const WritingResultPreview: FC<WritingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const writingData = JSON.parse(result.feedback) as WritingMockTestData;

  const {
    questionOneScore = "0",
    questionOneFeedback = "",
    questionOneSuggestions = "",
    questionTwoScore = "0",
    questionTwoFeedback = "",
    questionTwoSuggestions = "",
  } = writingData ?? {};

  return (
    <Box>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="teal"
        size="lg"
        width="full"
        _hover={{
          transform: "translateY(-2px)",
          shadow: "md",
        }}
        transition="all 0.2s"
      >
        <HStack width="full" justify="space-between">
          <HStack>
            <Icon as={FiEdit} />
            <Text>View Writing Result</Text>
          </HStack>
          <Icon as={FiChevronRight} />
        </HStack>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderRadius="xl" overflow="hidden">
          <ModalHeader 
            borderBottom="1px" 
            borderColor="gray.100"
            bg="teal.50"
            py={4}
          >
            <HStack>
              <Icon as={FiEdit} color="teal.500" />
              <Text color="teal.700">Writing Test Results</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py={6}>
            <WritingResultAnswer
              questionOneScore={questionOneScore}
              questionOneFeedback={questionOneFeedback}
              questionOneSuggestions={questionOneSuggestions}
              questionTwoScore={questionTwoScore}
              questionTwoFeedback={questionTwoFeedback}
              questionTwoSuggestions={questionTwoSuggestions}
            />
          </ModalBody>

          <ModalFooter 
            borderTop="1px" 
            borderColor="gray.100"
            bg="gray.50"
          >
            <Button
              variant="ghost"
              onClick={onClose}
              size="lg"
              _hover={{
                bg: "gray.100",
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
